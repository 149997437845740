<template>
  <b-modal
    :id="id"
    size="lg"
    title="Cập nhật thông tin báo cáo"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Cập nhật"
    centered
    @ok="accept($event)"
    @hidden="resetData"
  >

    <div class="addBusiness-fix ">
      <my-tabs
        :listTabs="listTabs"
        defaultActive="info"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <validation-observer ref="tabAccount">
        <info-equip
          v-if="isRendered('info')"
          ref="info"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeTab==='info'}"
        />
        <service
          v-if="isRendered('service')"
          :reportMonthAccidentId="dataDetail.id"
          class="tab-pane"
          :class="{'active':activeTab==='service'}"
        />
        <TableOther
          v-if="isRendered('other')"
          :reportMonthAccidentId="dataDetail.id"
          class="tab-pane"
          :class="{'active':activeTab==='other'}"
        />
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoEquip from './tab-edit/Info.vue'
import Service from './tab-edit/Service.vue'
import TableOther from './tab-edit/TableOther.vue'

export default {
  components: {
    MyTabs,
    InfoEquip,
    ValidationObserver,
    Service,
    TableOther,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      dataInput: {
        reportMonthAccidentType: JSON.parse(localStorage.getItem('userData')).roleIdentity,
        command: '',
        launchingCeremony: '',
        content: '',
        cost1: '',
        cost2: '',
        result: '',
        disadvantage: '',
        request: '',
      },
      activeTab: null,
      detailId: '',
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'service',
          title: 'Danh sách tiêu chí',
          icon: 'ListIcon',
          isRendered: false,
        },
        {
          key: 'other',
          title: 'Các nội dung khác',
          icon: 'CheckSquareIcon',
          isRendered: false,
        },
      ],
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          reportMonthAccidentType: this.dataDetail.reportMonthAccidentType,
          command: this.dataDetail.command,
          launchingCeremony: this.dataDetail.launchingCeremony,
          content: this.dataDetail.content,
          cost2: this.dataDetail.cost2,
          cost1: this.dataDetail.cost1,
          result: this.dataDetail.result,
          disadvantage: this.dataDetail.disadvantage,
          request: this.dataDetail.request,
        }
      }
    },

    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    activeTab(val) {
      if (val === null) {
        this.activeTab = 'info'
        this.listTabs[0].isRendered = true
      }
    },
  },

  created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'info'
      this.listTabs[0].isRendered = true
    }
  },

  methods: {
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.tabAccount.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
      this.activeItem = 'info'
    },
    resetData() {
      this.activeTab = null
      this.dataInput = {
        reportMonthAccidentType: JSON.parse(localStorage.getItem('userData')).roleIdentity,
        command: '',
        launchingCeremony: '',
        content: '',
        cost: '',
        result: '',
        disadvantage: '',
        request: '',
      }
    },
  },
}
</script>

<style lang="scss">
#modal-edit-report{
  .modal-dialog{
    max-width: 1126px;
  }
  .page-container{
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 0%),
  }
  .tab-container{
    margin-bottom: 0,
  }
}
</style>
