<template>
  <b-modal
    :id="id"
    size="sm"
    title="Thêm tiêu chí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-final-report">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group>
            <label for="InputHelp">Tên nội dung<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="{required: `Tên nội dung là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Đơn vị</label>
            <b-form-input
              v-model="dataInput.unit"
            />
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Số lượng</label>
            <b-form-input
              v-model="dataInput.data"
              type="number"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      year: {
        required: 'Tên nội dung là bắt buộc',
      },
      dataInput: {
        name: '',
        unit: '',
        data: 0,
      },
    }
  },
  methods: {
    handleCreate(bvModalEvt) {
      const model = {
        ...this.dataInput,
        data: Number(this.dataInput.data),
      }
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', model)
        }
      })
    },
    resetData() {
      this.dataInput = {}
    },
  },

}
</script>

<style lang="scss">
.modal-final-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
