<template>
  <div>
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm tiêu chí'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :hideSearch="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @importFile="() => {}"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"

      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
    />
    <!--Phần Bảng -->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
        <!-- Chức năng -->
        <span v-else-if="props.column.field == 'action'">
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="text-body ml-2"
            @click="deleteItem(props.row.id)"
          />

        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
    <!-- thêm mới modal  -->
    <modal-create-other
      :id="modalIdCreate"
      title="Thêm nội dung"
      @accept="handleCreateOtherContent"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'
import ModalCreateOther from './ModalCreateOther.vue'

// import EditEquip from './components/EditEquip.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    ConfirmModal,
    BBadge,
    ModalCreateOther,
    // EditEquip,
  },
  props: {
    reportMonthAccidentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      totalRecord: 10,
      confirmModalId: 'confirm-modal-other',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalIdCreate: 'modal-id-created',
      dataList: [],

      columns: [
        {
          label: 'TÊN NỘI DUNG',
          field: 'name',
          sortable: false,
        },
        {
          label: 'ĐƠN VỊ',
          field: 'unit',
          sortable: false,
        },
        {
          label: 'SỐ LƯỢNG',
          field: 'data',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        reportMonthAccidentId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {

    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    async handleCreateOtherContent(param) {
      const payload = {
        ...param,
        reportMonthAccidentId: this.reportMonthAccidentId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_REPORT_MONTH_ACCIDENT_OTHER, payload).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.fetchData(this.urlQuery)
        }
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    // Mở modal tạo và chỉnh sửa kiểm định viên

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Thực hiện delete
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        reportMonthAccidentId: this.reportMonthAccidentId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_REPORT_MONTH_ACCIDENT_OTHER, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      this.urlQuery.reportMonthAccidentId = this.reportMonthAccidentId
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_REPORT_MONTH_ACCIDENT_OTHER, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    // Hiển thị modal thêm
    showModalCreate() {
      this.$bvModal.show(this.modalIdCreate)
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
