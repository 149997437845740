var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"labor-recruitment-id"}},[_c('div',{staticClass:"scroll-table table-model"},[_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'data')?_c('span',[_c('b-form-input',{attrs:{"id":("focus-input-" + (props.row.titleReportId)),"value":props.row.data},on:{"focus":function($event){return _vm.focusTextChange(props.row.titleReportId)},"change":function($event){return _vm.handleCountData($event, props.row.titleReportId, props.row.note)}}})],1):(props.column.field === 'note')?_c('span',[(props.row.isNotReportDepartment)?_c('span',[_c('b-form-input',{attrs:{"id":("focus-inputs-" + (props.row.titleReportId)),"value":props.row.note},on:{"focus":function($event){return _vm.focusChangeValue(props.row.titleReportId)},"change":function($event){return _vm.handleCountNote($event, props.row.titleReportId, props.row.data)}}})],1):_vm._e()]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }