<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
        xl="6"
        lg="6"
      >
        <b-form-group
          label-for="command"
        >
          <label for="command">Công tác chỉ đạo, ban hành kế hoạch triển khai, kiểm tra, đôn đốc thực hiện</label>
          <b-form-textarea
            v-model="dataInput.command"
            rows="3"
            no-resize
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="6"
        lg="6"
      >
        <b-form-group
          label-for="launchingCeremony"
        >
          <label for="launchingCeremony">Lễ phát động Tháng hành động về an toàn, vệ sinh lao động (nếu có)</label>
          <b-form-textarea
            v-model="dataInput.launchingCeremony"
            rows="3"
            no-resize
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="6"
        lg="6"
      >
        <b-form-group
          label-for="content"
        >
          <label for="content">Nội dung các hoạt động đã triển khai trong Tháng hành động</label>
          <b-form-textarea
            v-model="dataInput.content"
            rows="3"
            no-resize
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="6"
        lg="6"
      >
        <b-form-group
          label-for="cost1"
        >
          <label for="cost1">Ngân sách nhà nước hoặc kinh phí của cơ sở sản xuất kinh doanh</label>
          <b-form-textarea
            v-model="dataInput.cost1"
            rows="3"
            no-resize
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        xl="6"
        lg="6"
      >
        <b-form-group
          label-for="cost2"
        >
          <label for="cost2">Ngân sách hỗ trợ, vận động từ doanh nghiệp và các nguồn hợp pháp khác</label>
          <b-form-textarea
            v-model="dataInput.cost2"
            rows="3"
            no-resize
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        xl="6"
        lg="6"
      >
        <b-form-group
          label-for="result"
        >
          <label for="result">Những kết quả đã đạt được; bài học kinh nghiệm</label>
          <b-form-textarea
            v-model="dataInput.result"
            rows="3"
            no-resize
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="6"
        lg="6"
      >
        <b-form-group
          label-for="disadvantage"
        >
          <label for="disadvantage">Khó khăn, tồn tại</label>
          <b-form-textarea
            v-model="dataInput.disadvantage"
            rows="3"
            no-resize
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="6"
        lg="6"
      >
        <b-form-group
          label-for="request"
        >
          <label for="request">Kiến nghị, đề xuất</label>
          <b-form-textarea
            v-model="dataInput.request"
            rows="3"
            no-resize
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {}
  },
  methods: {

  },
}
</script>
